import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Footer from "@src/components/grid/footer"
import ClearbitConverisonForm from "@src/components-smart/forms/clearbit-conversion-form"
import parse from "html-react-parser"
import HeroForm from "./hero-form"

const HomeHero = ({ data }) => {
  const { Logos, FormLink, Heading: headingText, TextBlock, LogoRowText } = data

  return (
    <header>
      <Section theme="white">
        <SectionBackground>
          <div className="hero-image__homeoverlay" />
          <div className="hero-image__homecircle hero-image__homecircle--a">
            <div className="homepage-images__hero-planet homepage-images__hero-planet--fb">
              <StaticImage
                src="../../images/homepage/hero/Meta Planet.png"
                alt="meta planet"
              />
            </div>
            <div className="homepage-images__hero-planet homepage-images__hero-planet--gg">
              <StaticImage
                src="../../images/homepage/hero/Google Planet.png"
                alt="Google planet background"
              />
            </div>
          </div>
          <div className="hero-image__homecircle hero-image__homecircle--b">
            <div className="homepage-images__hero-planet homepage-images__hero-planet--yt">
              <StaticImage
                src="../../images/homepage/hero/YouTube Planet.png"
                alt="YouTube planet"
              />
            </div>
            <div className="homepage-images__hero-planet homepage-images__hero-planet--tw">
              <StaticImage
                src="../../images/homepage/hero/Twitter Planet.png"
                alt="Twitter planet"
              />
            </div>
            <div className="homepage-images__hero-planet homepage-images__hero-planet--rd">
              <StaticImage
                src="../../images/homepage/hero/Reddit Planet.png"
                alt="Reddit planet"
              />
            </div>
          </div>
          <div className="hero-image__homecircle hero-image__homecircle--c">
            <div className="homepage-images__hero-planet homepage-images__hero-planet--sc">
              <StaticImage
                src="../../images/homepage/hero/tiktok-planet.png"
                alt="Tik Tok planet"
              />
            </div>
            <div className="homepage-images__hero-planet homepage-images__hero-planet--ld">
              <StaticImage
                src="../../images/homepage/hero/LinkedIn Planet.png"
                alt="LinkedIn planet"
              />
            </div>
          </div>
          <div className="hero-image hero-image--homepage-gradient">
            <StaticImage
              alt="lunio product dashboard"
              src="../../images/homepage/hero/Core and Yellow Gradient.png"
            />
          </div>
          <div className="hero-image hero-image--homepage">
            <StaticImage
              alt="lunio product dashboard"
              src="../../images/homepage/hero/db-main.png"
            />
          </div>
        </SectionBackground>
        <SectionContent hero>
          <Rows>
            <Columns count="2">
              <Content gap="lg" paddingLeft="none" paddingRight="none">
                <Heading className="lap-mt-lg superheading" level={1}>
                  {parse(headingText)}
                </Heading>
                <Copy>
                  <p>{TextBlock}</p>
                </Copy>
                <Footer>
                  <HeroForm formId="1d61597f-f19c-4c86-847d-ecb759d67180" />
                  <div
                    className="flex flex--wrap mt-md"
                    style={{ columnGap: 16, rowGap: 8 }}
                  >
                    <p className="text--small mb-xs">{LogoRowText}</p>
                    {Logos.map((item, index) => {
                      const img = getImage(item.logoImage.localFile)
                      return (
                        <div
                          style={{ maxWidth: "100%", width: "80px" }}
                          key={index}
                        >
                          <GatsbyImage
                            image={img}
                            alt={item.logoImage.alternativeText}
                          />
                        </div>
                      )
                    })}
                  </div>
                </Footer>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </header>
  )
}

export default HomeHero
