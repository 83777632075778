import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import Rows from "@src/components/grid/rows"
import Columns from "@src/components/grid/columns"
import React from "react"
import Ticker from "react-ticker"

const HomeBannerText = ({ text }) => {
  return (
    <div className="flex flex--align-center">
      {text.map(({ Statistic }, index) => (
        <div key={index} className="flex flex--align-center mr-md">
          <p className="scroll-container__text mr-md">{Statistic}</p>{" "}
          <div className="scroll-container__seperator" />
        </div>
      ))}
    </div>
  )
}

const HomeBanner = ({ data }) => {
  const { LeftText, Text } = data

  return (
    <Section theme="dark">
      <SectionBackground>
        <div className="scroll-container__bg" />
      </SectionBackground>
      <SectionContent paddingBot="none" paddingTop="none">
        <Rows>
          <Columns count="1">
            <div className="scroll-container my-sm">
              <p className="h4 text--md mr-md">{LeftText}</p>
              <div className="scroll-container__inner">
                <Ticker>
                  {() => {
                    return <HomeBannerText text={Text} />
                  }}
                </Ticker>
              </div>
            </div>
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default HomeBanner
