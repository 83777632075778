import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import parse from "html-react-parser"

const HomeImageSection = ({ data }) => {
  const { SectionHeading, ColumnText } = data

  return (
    <Section>
      <SectionContent
        width="wider"
        paddingTop="none"
        paddingBot="lg"
        className="home-image--section"
      >
        <Rows>
          <Columns count={1} className="mb-lg">
            <Heading level={2} alignment="center">
              {parse(SectionHeading)}
            </Heading>
          </Columns>
          <div className="home-image--mobile flex flex--align-center flex--justify-center">
            <StaticImage
              src="../../images/homepage/hiw-image/hiw-mobile.jpg"
              alt="how lunio works"
            />
          </div>
          <div className="relative home-image">
            <div className="home-image__arrow--top">
              <div>
                <p className="h4 h4--sm  ml-sm text--center">
                  Enhanced Targeting Signals
                </p>
                <p className="text--color-grey text--small  ml-sm text--center">
                  <i>Feed optimisations with accurate data</i>
                </p>
              </div>
              <svg className="home-image__arrow-svg" viewBox="60 60 980 190">
                <defs>
                  <radialGradient
                    id="green-gradient"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#31d618" />
                    <stop offset="0.506" stop-color="#31d618" />
                    <stop offset="1" stop-color="#31d618" stop-opacity="0" />
                  </radialGradient>
                </defs>
                <path
                  d="M 1026 261 L 1026 134 C 1026.087 122.258 1021.409 112.012 1013.698 104.301 C 1005.988 96.591 995.741 91.913 984 92 L 134 92 C 122.258 91.913 112.012 96.591 104.301 104.301 C 96.591 112.012 91.913 122.258 92 134 L 92 135 L 88 135 L 88 134 C 88.087 121.441 93.258 109.687 101.473 101.473 C 109.687 93.259 121.441 88.087 134 88 L 984 88 C 996.559 88.087 1008.312 93.258 1016.527 101.473 C 1024.741 109.687 1029.913 121.441 1030 134 L 1030 261 Z"
                  className="home-image__arrow"
                />
                <circle
                  className="home-image__dot-green"
                  r="8"
                  fill="url(#green-gradient)"
                />
                <path
                  d="M 78.991 125.148 L 86.537 136.003 C 87.247 136.829 88.519 137.621 89.473 137.704 C 90.426 137.788 91.817 137.228 92.659 136.538 L 101.975 127.158 L 99.137 124.34 L 89.821 133.72 C 89.783 133.916 90 133.735 89.821 133.72 C 89.642 133.704 89.824 133.919 89.821 133.719 L 82.275 122.864 Z"
                  className="home-image__arrow"
                />
              </svg>
            </div>
            <div className="home-image__columns">
              <div style={{ maxWidth: 288 }}>
                <StaticImage
                  src="../../images/homepage/hiw-image/lunio-sources.png"
                  alt="user sources"
                />
              </div>
              <div className="home-image__between flex flex--column mx-sm">
                <p className="h4 h4--sm  ml-sm">Unknown Users</p>
                <StaticImage
                  className="home-image__pointer"
                  src="../../images/homepage/hiw-image/arrow-1.png"
                  alt="unknown users arrow"
                />
              </div>
              <div className="home-image__main">
                <StaticImage
                  src="../../images/homepage/hiw-image/dashboard.png"
                  alt="lunio dashboard"
                />
              </div>
              <div className="home-image__between flex flex--column mx-sm">
                <p className="h4 h4--sm text--color-green mt-xl ml-sm">
                  Real Users
                </p>
                <StaticImage
                  src="../../images/homepage/hiw-image/arrow-3.png"
                  alt="green users arrow"
                />
                <StaticImage
                  src="../../images/homepage/hiw-image/arrow-2.png"
                  alt="click data arrow"
                />
                <p className="h4 h4--sm  ml-sm">Post-Click Data</p>
                <p className="text--color-grey text--small  ml-sm">
                  <i>To optimise for real buying signals</i>
                </p>
              </div>
              <div>
                <StaticImage
                  src="../../images/homepage/hiw-image/evolve.png"
                  alt="browser"
                />
              </div>
            </div>
            <div className="home-image__arrow--bottom">
              <svg
                viewBox="78.926 30 530.074 173"
                className="home-image__arrow-svg"
              >
                <defs>
                  <radialGradient
                    id="red-gradient"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientUnits="objectBoundingBox"
                  >
                    <stop offset="0" stop-color="#fb232a" />
                    <stop offset="0.506" stop-color="#fb232a" />
                    <stop offset="1" stop-color="#fb232a" stop-opacity="0" />
                  </radialGradient>
                </defs>
                <path
                  d="M 609 30 L 609 157 C 608.913 169.559 603.742 181.313 595.527 189.527 C 587.313 197.742 575.559 202.913 563 203 L 134 203 C 121.441 202.913 109.687 197.742 101.473 189.527 C 93.258 181.313 88.087 169.559 88 157 L 88 156 L 92 156 L 92 157 C 91.913 168.742 96.591 178.988 104.301 186.699 C 112.012 194.409 122.258 199.087 134 199 L 563 199 C 574.742 199.087 584.988 194.409 592.699 186.699 C 600.409 178.988 605.087 168.742 605 157 L 605 30 Z"
                  className="home-image__arrow"
                />
                <circle
                  className="home-image__dot-red"
                  r="8"
                  fill="url(#red-gradient)"
                />
                <path
                  d="M 78.926 164.872 L 86.472 154.018 C 87.182 153.191 88.455 152.399 89.408 152.316 C 90.362 152.232 91.753 152.792 92.595 153.482 L 101.911 162.862 L 99.073 165.68 L 89.757 156.3 C 89.757 156.3 89.935 156.285 89.757 156.3 C 89.578 156.316 89.76 156.1 89.757 156.3 L 82.211 167.156 L 78.926 164.872 Z"
                  className="home-image__arrow"
                />
              </svg>
              <div className="mt-sm">
                <p className="h4 h4--sm text--color-red ml-sm text--center">
                  Fake Users Excluded
                </p>
                <p className="text--color-grey text--small  ml-sm text--center">
                  <i>Excluded through IP and Audiences</i>
                </p>
              </div>
            </div>
          </div>
          <Columns className="tab-mt-lg" count="3">
            {ColumnText.map((item, index) => (
              <Content gap="sm" className="mx-md mt-sm" key={index}>
                <Heading level={4} className="h4--sm">
                  {item.heading}
                </Heading>
                <Copy contentToParse={item.text} />
              </Content>
            ))}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default HomeImageSection
