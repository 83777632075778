import React from "react"
import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import { StaticImage } from "gatsby-plugin-image"
import Marquee from "react-fast-marquee"

const ScrollingReviewBadges = () => {
  return (
    <Section>
      <SectionBackground>
        <div className="homepage-images__badges--outer height--full width--full flex flex--align-center">
          <Marquee speed={40}>
            <div className="homepage-images__badges width--full flex flex--justify-between flex--align-center">
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/momentum_fall_2023.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/leader_summer_2023.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/leader_fall_2023.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/leader_europe_2023.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/leader_emea_2023.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-easiest-summer.png"
                  alt="g2 reviews easiest click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-implementable-fall.png"
                  alt="g2 reviews implementable click fraud fall"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-implementable-summer.png"
                  alt="g2 reviews implementable click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-leader-fall.png"
                  alt="g2 reviews leader click fraud fall"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-relationship-spring.png"
                  alt="g2 reviews relationship summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-leader-spring.png"
                  alt="g2 reviews leader click fraud spring"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-usability-summer.png"
                  alt="g2 reviews usability click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-relationship-summer.png"
                  alt="g2 reviews relationship click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-leader-summer.png"
                  alt="g2 reviews leader click fraud summer"
                />
              </div>
              <div className="homepage-images__badge">
                <StaticImage
                  src="../../images/homepage/badges/g2-leader-winter.png"
                  alt="g2 reviews leader click fraud summer"
                />
              </div>
            </div>
          </Marquee>
        </div>
      </SectionBackground>

      <SectionContent paddingTop="none" paddingBot="none">
        <div className="flex flex--align-center flex--justify-center">
          <div className="homepage-images__badge--container mob-px-xxl mob-py-xxl">
            <h2 className="text--center">
              Rated a G2 Leader.{" "}
              <span className="text-gradient text-gradient__midgard">
                Again
              </span>
              .
            </h2>
          </div>
        </div>
      </SectionContent>
    </Section>
  )
}

export default ScrollingReviewBadges
