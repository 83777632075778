import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import IndustryCard from "@src/components-smart/carousel/industry-card"

const industrySliderCards = [
  <IndustryCard
    category="Retail"
    title="Go Beyond Your Target ROAS"
    text="Slash your CPAs and maximise performance by concentrating 100% of your ad spend on genuine buyers. Use Lunio to make every engagement count by eliminating fake clicks across Shopping Ads, Performance Max, Display and Search campaigns."
  >
    <div className="industry-slider-images industry-slider-images__ecommerce">
      <StaticImage
        src="../../images/homepage/industry/industry_ecommerce.png"
        alt="ecommerce industry"
      />
    </div>
  </IndustryCard>,
  <IndustryCard
    category="SaaS"
    title="Double Your Pipeline, Not Your Ad Spend"
    text="Take guesswork out of your MRR and ARR growth rate forecasting. Use Lunio to make paid media results more reliable and consistent across all ad platforms by eliminating junk leads."
  >
    <div className="industry-slider-images industry-slider-images__saas">
      <StaticImage
        src="../../images/homepage/industry/industry_saas.png"
        alt="saas industry"
      />
    </div>
  </IndustryCard>,
  <IndustryCard
    category="Agencies"
    title="Get More Sales & Signups For Clients"
    text="Strengthen your service offering and stay ahead of the competition by offering paid media protection your clients won’t get elsewhere. Block fake clicks, boost client ROAS, and branch into new marketing channels without putting PPC budgets or your own reputation at risk."
  >
    <div className="industry-slider-images industry-slider-images__agencies">
      <StaticImage
        src="../../images/homepage/industry/industry_agencies.png"
        alt="marketing agencies"
      />
    </div>
  </IndustryCard>,
  <IndustryCard
    category="Travel"
    title="Turn More Lookers Into Bookers"
    text="Fraudulent activity in travel always increases during peak booking periods. Pre-empt the spike before it hits your site by blocking all fake ad interactions. Higher-quality traffic leads to an uplift in conversion rates and healthier look-to-book ratios of 7% or more."
  >
    <div className="industry-slider-images industry-slider-images__travel">
      <StaticImage
        src="../../images/homepage/industry/industry_travel.png"
        alt="travel industry"
      />
    </div>
  </IndustryCard>,
  <IndustryCard
    category="Financial Services"
    title="End Overspend Overnight"
    text="Financial CPCs often exceed $10. That means the cost of fake clicks stacks up quicker relative to other sectors. By ensuring all traffic from your ads comes from real potential customers, Lunio lowers your cost-per-acquisition and stops fake leads flooding your CRM."
  >
    <div className="industry-slider-images industry-slider-images__finance">
      <StaticImage
        src="../../images/homepage/industry/industry_finance.png"
        alt="financial services industry"
      />
    </div>
  </IndustryCard>,
  <IndustryCard
    category="Education"
    title="Maximise Enrollment Efficiency"
    text="Online courses and remote learning are the new norm post-pandemic. But that’s led to a spike in fraudulent bot-driven enrollments. Lunio screens them out pre-click to reduce your cost-per-enrollment, make life easier for your admissions team and improve enrollment UX."
  >
    <div className="industry-slider-images industry-slider-images__education">
      <StaticImage
        src="../../images/homepage/industry/industry_education.png"
        alt="educational industries"
      />
    </div>
  </IndustryCard>,
]

export default industrySliderCards
