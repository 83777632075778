import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HomeTricolumn = ({ data }) => {
  const { HeadingText, Item } = data

  return (
    <Section theme="light">
      <SectionContent paddingTop="xs">
        <Rows>
          <Columns count={1} className="mb-md">
            <Heading alignment="center">{HeadingText}</Heading>
          </Columns>
          <Columns count={3}>
            {Item.map((content, index) => {
              const img = getImage(content.image.localFile)

              return (
                <Content gap="sm" key={index}>
                  <div style={{ width: 128 }} className="mb-xxs">
                    <GatsbyImage
                      image={img}
                      alt={content.image.alternativeText}
                    />
                  </div>
                  <Heading level={4}>{content.title}</Heading>
                  <Copy contentToParse={content.text} />
                </Content>
              )
            })}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default HomeTricolumn
