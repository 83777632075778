import React from "react"
import { Carousel } from "../index"
import CaseStudyCard from "./case-study-card"

const CaseStudyCarousel = ({ data }) => {
  const responsiveSlides = {
    full: 1,
    mid: 1,
    small: 1,
  }

  return (
    <Carousel
      autoplay={true}
      slidesToShow={responsiveSlides}
      infinite
      className="careers-slider"
      slides={data.map((item, index) => (
        <CaseStudyCard
          key={index}
          title={item.bigText}
          text={item.littleText}
          quote={item.quote}
          author={item.authorName}
          authorTitle={item.authorTitle}
          link={item.buttonLink}
          linkText={item.buttonText}
          logo={item.logo}
          headshot={item.authorImage}
        />
      ))}
    />
  )
}

export default CaseStudyCarousel
