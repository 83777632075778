import CaseStudyCarousel from "@src/components-smart/carousel/case-studies/case-study-carousel"
import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import Heading from "@src/components/content/heading"
import React from "react"

const HomeCaseStudies = ({ data }) => {
  const { headingText, testimonialItems } = data

  return (
    <Section>
      <SectionBackground>
        <div className="case-studies__background" />
      </SectionBackground>
      <SectionContent paddingBot="md" paddingTop="none">
        <Heading alignment="center" className="my-lg">
          {headingText}
        </Heading>
        <div className="border--rounded cta__background">
          <CaseStudyCarousel data={testimonialItems} />
        </div>
      </SectionContent>
    </Section>
  )
}

export default HomeCaseStudies
