import React from "react"
import { graphql } from "gatsby"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import HomeHero from "@src/sections/home/hero"
import HomePotential from "@src/sections/home/potential"
import StatisticsBreak from "@src/sections/home/statistics-break"
import IndustrySlider from "@src/sections/home/industy-slider"
import MissionSection from "@src/sections/home/mission-section"
import HomeCaseStudies from "@src/sections/home/case-study-quotes"
import ScrollingReviewBadges from "@src/sections/home/scrolling-review-badges"
import HomeBanner from "@src/sections/home/home-banner"
import HomeBoxes from "@src/sections/home/boxes"
import HomeTricolumn from "@src/sections/home/tri-column"
import HomeStatBoxes from "@src/sections/home/stat-boxes"
import HomeImageSection from "@src/sections/home/home-image"

function IndexPage({ data }) {
  const {
    SEO,
    Hero,
    Ticker,
    Diagram,
    BiColumn,
    Triboxes,
    LunioDifference,
    Statboxes,
    testimonialBoxes,
  } = data.strapiPageHomeNew
  const { boxes } = data.wpIndustryPage.pageData

  const seoObj = {
    title: SEO.metaTitle,
    description: SEO.metaDesc,
    image: SEO.shareImage.localFile.publicURL,
    imageAlt: SEO.shareImage.alternativeText,
    url: "/",
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <HomeHero data={Hero} />
      <HomeBanner data={Ticker} />
      <HomePotential data={BiColumn} />
      <HomeImageSection data={Diagram} />
      <HomeBoxes data={Triboxes} imageData={boxes} />
      <HomeStatBoxes data={Statboxes} />
      <HomeTricolumn data={LunioDifference} />
      <HomeCaseStudies data={testimonialBoxes} />
      <ScrollingReviewBadges />
    </Main>
  )
}

export const query = graphql`
  query NewHomepageQuery {
    wpIndustryPage(id: { eq: "cG9zdDoyNjE2NA==" }) {
      pageData {
        statBoxes {
          text
          headlineFigure
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
        boxes {
          backgroundStyles
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
          imageStyles {
            styleKey
            styleValue
          }
        }
      }
    }
    strapiPageHomeNew {
      SEO {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        FormLink
        Heading
        TextBlock
        LogoRowText
        Logos {
          logoImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          imageAltText
        }
      }
      Diagram {
        ColumnText {
          text
          heading
        }
        SectionHeading
      }
      Ticker {
        LeftText
        Text {
          Statistic
        }
      }
      BiColumn {
        HeadingText
        ParagraphText
        Button {
          link
          text
        }
      }
      Triboxes {
        heading
        text
      }
      LunioDifference {
        HeadingText
        Item {
          text
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
          }
        }
      }
      Statboxes {
        heading
        content
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      testimonialBoxes {
        headingText
        testimonialItems {
          authorName
          authorTitle
          bigText
          buttonLink
          buttonText
          littleText
          quote
          authorImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
