import React from "react"
import Section from "@components/containers/section"
import SectionContent from "@components/containers/section-content"
import HomeStatsCarousel from "@src/components-smart/carousel/home-stats-carousel"

function HomeStatBoxes({ data }) {
  return (
    <Section theme="light">
      <SectionContent paddingTop="none" paddingBot="sm">
        <div className="homepage-stats">
          <HomeStatsCarousel cardsData={data} />
        </div>
      </SectionContent>
    </Section>
  )
}

export default HomeStatBoxes
