import IndustryPageCard from "@src/components/containers/industry-page-card"
import Section from "@src/components/containers/section"
import SectionBackground from "@src/components/containers/section-background"
import SectionContent from "@src/components/containers/section-content"
import React from "react"

const HomeBoxes = ({ data, imageData }) => {
  const BoxOne = data[0]
  const BoxOneImageData = imageData[0]
  const BoxTwo = data[1]
  const BoxTwoImageData = imageData[1]
  const BoxThree = data[2]
  const BoxThreeImageData = imageData[2]

  return (
    <Section theme="light">
      <SectionBackground></SectionBackground>
      <SectionContent paddingBot="md">
        <div className="industry-grid">
          <IndustryPageCard
            title={BoxOne.heading}
            textBlock={BoxOne.text.replace(/<\/?[^>]+(>|$)/g, "")}
            doubleHeight={true}
            backgroundStyles="linear-gradient(180deg, #10085C 0%, #6833C9 50%, #10085C 100%)"
            image={BoxOneImageData.image}
            imageStyles={{ bottom: 0, right: 0, width: 320 }}
          />
          <IndustryPageCard
            title={BoxTwo.heading}
            textBlock={BoxTwo.text.replace(/<\/?[^>]+(>|$)/g, "")}
            doubleHeight={false}
            image={BoxTwoImageData.image}
            imageStyles={{ bottom: -20, right: -30 }}
          />
          <IndustryPageCard
            title={BoxThree.heading}
            textBlock={BoxThree.text.replace(/<\/?[^>]+(>|$)/g, "")}
            doubleHeight={false}
            backgroundStyles="#CE12B5"
            image={BoxThreeImageData.image}
            imageStyles={{ bottom: 8, right: 0 }}
          />
        </div>
      </SectionContent>
    </Section>
  )
}

export default HomeBoxes
