import React from "react"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"

const IndustryCard = ({ category, title, text, children }) => {
  return (
    <Rows className="relative">
      <Columns count="2">
        <Content
          gap="lg"
          paddingRight="none"
          className="ml-md mr-md my-lg lap-my-xxl lap-ml-xl lap-mr-none z-10"
        >
          <div>
            <p className="h4 h4--small text--color-nebula">{category}</p>
            <Heading level={2}>{title}</Heading>
          </div>
          <Copy>
            <p>{text}</p>
          </Copy>
        </Content>
        {children}
      </Columns>
    </Rows>
  )
}

export default IndustryCard
