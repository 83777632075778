import React from "react"
import Carousel from "./default"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Card from "@src/components/containers/card"

function HomeStatsCarousel({ cardsData }) {
  const cards = cardsData.map(({ heading, content, image }, index) => {
    const img = getImage(image.localFile)

    return (
      <Card className="mr-md tab-mr-none" key={index} styleType="solid">
        <p className="h3 mb-xs">{heading}</p>
        <p className="mb-xs">{content}</p>
        <div style={{ maxWidth: 96 }}>
          <GatsbyImage image={img} alt={image.alternativeText} />
        </div>
      </Card>
    )
  })

  const responsiveSlides = {
    full: 4,
    mid: 2,
    small: 1,
  }

  return (
    <Carousel
      autoplay={true}
      slidesToShow={responsiveSlides}
      centered
      slides={cards}
    />
  )
}

export default HomeStatsCarousel
