import Copy from "@src/components/content/copy"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import CtaGroup from "@src/components/containers/cta-group"
import Button from "../../../components/interactive/button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseStudyCard = ({
  title,
  text,
  quote,
  author,
  authorTitle,
  link,
  linkText,
  logo,
  headshot,
}) => {
  const authorImage = getImage(headshot.localFile)
  const logoImage = getImage(logo.localFile)

  return (
    <Rows className="py-sm lap-py-lg px-sm lap-px-lg">
      <Columns count="2" className="careers-slider__columns">
        <Content paddingLeft="none">
          <div style={{ width: 120 }}>
            <GatsbyImage image={logoImage} alt={logo.alternativeText} />
          </div>
          <Copy>
            <p className="h2 text--color-white">{title}</p>
            <p className="text--color-white">{text}</p>
          </Copy>
          <CtaGroup>
            <Button href={link}>{linkText}</Button>
          </CtaGroup>
        </Content>
        <Content paddingLeft="none" paddingRight="sm">
          <FontAwesomeIcon
            className="text--color-midgard superheading"
            icon={faQuoteLeft}
          />
          <p className="text--color-white">{quote}</p>
          <div className="flex flex--align-center ">
            <div className="homepage-images__quote-author mr-sm">
              <GatsbyImage image={authorImage} alt={headshot.alternativeText} />
            </div>
            <div>
              <p className="text--strong text--color-white">{author}</p>
              <p className="text--color-white">{authorTitle}</p>
            </div>
          </div>
        </Content>
      </Columns>
    </Rows>
  )
}

export default CaseStudyCard
