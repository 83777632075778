import React, { useState } from "react"
import CtaGroup from "@src/components/containers/cta-group"
import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Copy from "@src/components/content/copy"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import Button from "@src/components/interactive/button"

const HomePotential = ({ data }) => {
  const { HeadingText, ParagraphText, Button: btn } = data

  return (
    <Section>
      <SectionContent>
        <Rows className="mb-xxl lap-mb-sm">
          <Columns count="2">
            <Content>
              <Heading className="text--color-purple" level={2}>
                {HeadingText}
              </Heading>
            </Content>
            <Content className="pt-xxs">
              <Copy contentToParse={ParagraphText} />
              <CtaGroup className="mt-xxs">
                <Button href={btn.link}>{btn.text}</Button>
              </CtaGroup>
            </Content>
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default HomePotential
